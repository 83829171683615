/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

function Seo({ title, description, canonicalUrl, noIndex }) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
          }
        }
      }
    `
  )

  const siteTitle = site.siteMetadata.title;
  const metaDescription = description || site.siteMetadata.description
  title = title ? `${title} - ${siteTitle}` : siteTitle;

  return (
    <Helmet>
      <title>{title}</title>
      <meta name="description" content={metaDescription} />
      {noIndex && <meta name="robots" content="noindex" />}
      {!noIndex && <meta name="robots" content="max-image-preview:large" />}
      {canonicalUrl && <link rel="canonical" href={canonicalUrl} />}
    </Helmet>
  )
}

export default Seo
