import React from "react"
import { Link, graphql } from "gatsby"

import Layout from "../components/layout"
import Header from "../components/header/header"
import Seo from "../components/seo/seo"
import Footer from "../components/footer/footer"
import { Helmet } from "react-helmet"
import { history } from "instantsearch.js/es/lib/routers"

import {
  inputs,
  hitsContainer,
  searchContentWrapper,
  searchPageTitle,
  searchInput,
  card,
  cardImage,
  cardBody,
  cardTitle,
  cardDescription,
  loadMoreButton,
} from "./zoeken.module.css"

import Client from "@searchkit/instantsearch-client"
import Searchkit from "searchkit"
import { Configure, InstantSearch, useInfiniteHits } from "react-instantsearch"
import MenuSelectWidget from "../components/menu-select-widget/menu-select-widget"
import CustomSearchBox from "../components/custom-search-box/custom-search-box"
import PillMenu from "../components/pill-menu/pill-menu"
import { FaPlus } from "react-icons/fa"
import imageApi from "../modules/imageApi"

const sk = new Searchkit({
  connection: {
    host: "https://27744cc57d384974baaa3785171b1233.europe-west4.gcp.elastic-cloud.com/",
    headers: {
      Authorization:
        "Basic d2Vic2l0ZV9ta2JzZXJ2aWNlZGVzazp3ZWJzaXRlX21rYnNlcnZpY2VkZXNr",
    },
  },
  search_settings: {
    search_attributes: [
      { field: "title", weight: 5 },
      { field: "subtitle", weight: 4 },
      { field: "description", weight: 2 },
      "body",
      "title.keyword",
    ],
    result_attributes: [
      "title",
      "subtitle",
      "description",
      "body",
      "url",
      "desk",
      "deskSlug",
      "deskCategory",
      "deskCategorySlug",
      "pillTypes",
      "imageUrl",
    ],
    facet_attributes: [
      {
        attribute: "desk",
        field: "desk.keyword",
        type: "string",
      },
      {
        attribute: "deskCategory",
        field: "deskCategory.keyword",
        type: "string",
      },
      {
        attribute: "pillTypes",
        field: "pillTypes.keyword",
        type: "string",
      },
    ],
  },
})

const searchClient = Client(sk)

const transformItems = (items) => {
  const sortOrder = ["Trending", "Nieuw"]
  const sortedItems = []
  const remainingItems = []

  items.forEach((item) => {
    if (sortOrder.includes(item.value)) {
      sortedItems.push(item)
    } else {
      remainingItems.push(item)
    }
  })

  const finalSortedItems = sortedItems.concat(remainingItems)
  return finalSortedItems
}

const SearchHit = ({ title, link, imageUrl, description }) => {
  return (
    <>
      <Link to={link} title={title}>
        <div className={card}>
          {imageUrl && (
            <img src={imageUrl} alt={title} width={400} className={cardImage} />
          )}
          <div className={cardBody}>
            <h2 className={cardTitle}>{title}</h2>
            {/* <p className={cardDescription}>{description}</p> */}
          </div>
        </div>
      </Link>
    </>
  )
}

const HitsList = () => {
  const { hits, showMore, isLastPage } = useInfiniteHits()

  return (
    <>
      {hits.length > 0 && <h4>Resultaten</h4>}
      <div className={hitsContainer}>
        {hits.map((hit) => {
          if (!hit.imageUrl) {
            hit.imageUrl =
              "//images.ctfassets.net/8kzekgmzhrvm/32zpVsrLSwTo9LVxUIjHIM/ecde2a45d6437064cdd45ee71574f463/placeholder3"
          }
          const processedImageUrl = imageApi.getSrc(hit.imageUrl, {
            w: 400,
            r: 10,
          })

          return (
            <SearchHit
              key={`search-hit-${hit.objectID}`}
              title={hit.title}
              link={hit.url}
              imageUrl={processedImageUrl}
              description={hit.description}
            />
          )
        })}
      </div>
      {!isLastPage && (
        <button onClick={showMore} className={loadMoreButton}>
          <FaPlus width={12} height={12} color="#575757" />
          Laad meer
        </button>
      )}
    </>
  )
}

// https://www.algolia.com/doc/guides/building-search-ui/going-further/routing-urls/react/
// Custom routing for instantsearch (Possible to add facets to url params)

const routing = {
  router: history(),
  stateMapping: {
    stateToRoute(uiState) {
      const indexUiState = uiState["contentful-entries"]
      return {
        q: indexUiState.query,
      }
    },
    routeToState(routeState) {
      return {
        ["contentful-entries"]: {
          query: routeState.q,
        },
      }
    },
  },
}

const SearchPage = ({ data }) => {
  const navigationDesks = data.navigationDesks.nodes

  return (
    <Layout>
      <Helmet>
        {/* ❗️ DO NOT JUST COPY+PASTE THIS META TAG ❗️ */}
        <meta name="robots" content="noindex, nofollow" />
        <link rel="canonical" href="https://www.mkbservicedesk.nl/zoeken/" />
        {/* ❗️ DO NOT JUST COPY+PASTE THIS META TAG ❗️ */}
      </Helmet>
      <Header desks={navigationDesks} />
      <main
        className="main"
        style={{
          maxWidth: "1400px",
          margin: "0 auto",
          backgroundColor: "#fff",
          paddingBottom: "25px",
        }}
      >
        <div className="container">
          <Seo title="Zoeken" />
          <div className={searchContentWrapper}>
            <h1 className={searchPageTitle}>Zoeken</h1>
            <div>
              <InstantSearch
                searchClient={searchClient}
                indexName="contentful-entries"
                routing={routing}
              >
                <Configure hitsPerPage={12} />

                <div className={inputs}>
                  <CustomSearchBox
                    placeholder="Zoeken"
                    className={searchInput}
                  />

                  <MenuSelectWidget placeholder="Categorie" attribute="desk" />
                  <MenuSelectWidget
                    placeholder="Subcategorie"
                    attribute="deskCategory"
                  />
                </div>

                <PillMenu
                  attribute="pillTypes"
                  transformItems={transformItems}
                />

                {/* <ClearRefinements /> */}

                <HitsList />
              </InstantSearch>
            </div>
          </div>
        </div>
      </main>
      <Footer />
    </Layout>
  )
}

export default SearchPage

export const pageQuery = graphql`
  query {
    navigationDesks: allContentfulDesk(sort: { fields: sortNavigation }) {
      nodes {
        id
        name
        slug2
        legacyId
        image {
          gatsbyImageData(layout: CONSTRAINED, width: 400)
        }
        sortNavigation
        ctaNavigation {
          image {
            title
            gatsbyImageData(layout: CONSTRAINED, width: 1180)
          }
          title
          slug
          categories {
            slug2
            desk {
              slug2
            }
          }
        }
        deskcategory {
          id
          name
          slug2
          desk {
            id
            name
            slug2
          }
          article {
            id
            title
            slug2
            updateDate
            categories {
              slug2
              desk {
                slug2
              }
            }
          }
        }
      }
    }
  }
`
