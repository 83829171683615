// extracted by mini-css-extract-plugin
export var card = "zoeken-module--card--ab2ac";
export var cardBody = "zoeken-module--card-body--f0833";
export var cardDescription = "zoeken-module--card-description--486cb";
export var cardImage = "zoeken-module--card-image--ba2a8";
export var cardTitle = "zoeken-module--card-title--71ba8";
export var hitsContainer = "zoeken-module--hits-container--5321d";
export var inputs = "zoeken-module--inputs--5cddb";
export var loadMoreButton = "zoeken-module--load-more-button--8492e";
export var searchContentWrapper = "zoeken-module--search-content-wrapper--94e2e";
export var searchInput = "zoeken-module--search-input--ab180";